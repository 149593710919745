import Service from './Service'

export default class Item extends Service {
    constructor() {
        super(Item.API);
    }

    getNews() {
        return this.requestGet(this.api +  "/new");
    }
}

Item.API = "/items/active"
