<template>
    <div>
        <Parallax :image="collection.banner.large" :parallaxId="parallaxId">
            <div class="container parallax-content">
                <div class="row">
                    <div class="col-md-5 col-sm-5">
                        <h2 class="parallax-title">Découvrez la collection {{ collection.name }}</h2>
                        <h4 class="">{{ collection.subtitle }}</h4>
                        <h5>{{ collection.description }}</h5>
                        <router-link :to="{ name: 'collection', params: { id: collection.id.toString() } }" class="btn btn-primary w-100">Découvrir la collection</router-link>
                    </div>
                </div>
            </div>
        </Parallax>
        <section class="d-flex justify-content-center linear-gradient"
                 :style="{ background: getBackground }">
            <div style="width: 90%; padding-top: 60px;" class="ml-m d-auto">
                <CarouselItems :items="collection.items"></CarouselItems>
            </div>
        </section>
    </div>
</template>

<script>
import CarouselItems from "@/components/items/CarouselItems";
import Parallax from "@/components/widgets/Parallax";

export default {
    name: "Collection",
    components: {
        CarouselItems,
        Parallax,
    },
    data(){
      return {
        background: "",
        imageObject: null
      }
    },
    props: {
        collection : Object,
        parallaxId: Number,
    },
    computed: {
      getBackground() {
          return this.background
      }
    },
    methods: {
        setBackgroundColor() {
            var canvas = document.createElement('canvas'),
                context = canvas.getContext && canvas.getContext('2d'),
                rgb = {r: 102, g: 102, b: 102}, // Set a base colour as a fallback for non-compliant browsers
                pixelInterval = 5, // Rather than inspect every single pixel in the image inspect every 5th pixel
                count = 0,
                i = -4,
                data, length;

            // return the base colour for non-compliant browsers
            if (!context) {
                alert('Your browser does not support CANVAS');
                return rgb;
            }

            // set the height and width of the canvas element to that of the image
            var height = canvas.height = this.imageObject.naturalHeight || this.imageObject.offsetHeight || this.imageObject.height,
                width = canvas.width = this.imageObject.naturalWidth || this.imageObject.offsetWidth || this.imageObject.width;

            context.drawImage(this.imageObject, 0, 0);

            try {
                data = context.getImageData(0, 0, width, height);
            } catch (e) {
                // catch errors - usually due to cross domain security issues
                return rgb;
            }

            data = data.data;
            length = data.length;
            while ((i += pixelInterval * 4) < length) {
                count++;
                rgb.r += data[i];
                rgb.g += data[i + 1];
                rgb.b += data[i + 2];
            }

            // floor the average values to give correct rgb values (ie: round number values)
            rgb.r = Math.floor(rgb.r / count);
            rgb.g = Math.floor(rgb.g / count);
            rgb.b = Math.floor(rgb.b / count);

            let ajout = 50, moy = 120;
            while((rgb.r + rgb.g + rgb.b)/3 < moy || rgb.r  < moy || rgb.g < moy || rgb.b < moy) {
                (rgb.r + ajout) > 255 ? rgb.r = 255 : rgb.r += ajout;
                (rgb.g + ajout) > 255 ? rgb.g = 255 : rgb.g += ajout;
                (rgb.b + ajout) > 255 ? rgb.b = 255 : rgb.b += ajout;
            }

            this.background = 'linear-gradient(' + this.toHex(rgb) + ', #ffffff)';
        },
        toHex(rgb) {
            return "#" + rgb.r.toString(16) + rgb.g.toString(16) + rgb.b.toString(16);
        }
    },
    mounted() {
        this.imageObject = new Image();
        this.imageObject.crossOrigin = "anonymous"
        this.imageObject.src = this.collection.banner.large;

        this.setBackgroundColor();
        this.imageObject.addEventListener("load",this.setBackgroundColor)
    },
    beforeDestroy() {
        this.imageObject.removeEventListener("load",this.setBackgroundColor)
    }
}
</script>

<style scoped>

</style>
