const authStore = {
    namespaces: true,
    state: () => ({
        initialized: false,
        me: null,
        auth: null,
        to: null,
        requireLogin: false,
        messageInfo: ""
    }),
    getters: {
        getMe(state) {
            return state.me
        },
        getToken(state) {
            return state.auth?.token ?? null
        },
        getRefreshToken(state) {
            return state.auth?.refreshToken ?? null
        },
        getAuth(state) {
            return state.auth
        },
        getRequireLogin(state) {
            return state.requireLogin
        },
        getTo(state) {
            return state.to
        },
        isAuthenticated(state) {
            return state.auth != null
        },
        isAdmin(state) {
            return (state.me?.roles ?? []).includes("ROLE_ADMIN")
        },
        fullname(state) {
            return state.me != null ? (state.me.lastName ?? "") + " " + (state.me.firstName ?? "") : ""
        },
        isConnected(state) {
            return state.auth != null
        },
        isInitialized(state) {
            return state.initialized
        },
        getMessageInfo(state) {
            return state.messageInfo
        },
    },
    mutations: {
        logout(state) {
            state.auth = null
            state.me = null
        },
        setMe(state, me)
        {
            state.me = me
        },
        setAuth(state, auth) {
            this.commit("updateAuth", auth)
        },
        setMessageInfo(state, messageInfo) {
            state.messageInfo = messageInfo
        },
        updateAuth(state, auth) {
            state.auth = auth

            var base64Url = auth.token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            state.me = JSON.parse(jsonPayload)
        },
        setRequireLogin(state, requireLogin) {
            state.requireLogin = requireLogin
        },
        setTo(state, to) {
            state.to = to
        },
        setInitialized(state, value) {
            state.initialized = value
        }
    },
    actions: {
        setMe({commit}, me)
        {
            return commit('setMe', me)
        },
        setMessageInfo({commit}, messageInfo)
        {
            return commit('setMessageInfo', messageInfo)
        },
        refreshToken({commit}, auth) {
            updateStorage(JSON.stringify(auth))
            commit("setAuth", {
                token: auth.token,
                refreshToken: auth.refreshToken,
                lastAuth: new Date(),
            })
        },
        setAuth({commit}, {auth, rememberMe}) {
            //console.log("set remember me in storage", rememberMe)
            localStorage.setItem("rememberMe", rememberMe)

            updateStorage(JSON.stringify(auth))
            commit("setAuth", {
                token: auth.token,
                refreshToken: auth.refreshToken,
                lastAuth: new Date(),
            })
        },
        logout({commit}) {
            //console.log("logout")
            commit("logout")
            localStorage.setItem("rememberMe", false)
            localStorage.removeItem('auth');
            sessionStorage.removeItem('auth');
        },
        setRequireLogin({commit}, requireLogin) {
            commit("setRequireLogin", requireLogin)
        },
        setTo({commit}, to) {
            commit("setTo", to)
        },
        initiateAuthStore({getters, commit, state}) {
            // Add event listener to share auth between tabs and navigators 
            // console.log("initiateAuthStore")
            window.addEventListener('storage', function(event) {
                //console.log("event on storage")
                if (!event.newValue) {
                    //console.log("not value in event")
                    return
                }

                if (event.key === 'getAuthStore') {
                    // console.log("getAuthStore")
                    if (!getters.isInitialized) {
                        //console.log("not initialized")
                        return
                    }
                    if (getters.getAuth == null) {
                        return
                    }
                    // Some tab asked for the memoryAuthStorage -> send it
                    localStorage.setItem('updateAuth', JSON.stringify(getters.getAuth));
                    localStorage.removeItem('updateAuth');
                } else if (event.key === 'updateAuth') {
                    // console.log("updateAuth")
                    // Not use commit setAuth to avoid subscription triggering
                    updateStorage(event.newValue)
                    let auth = JSON.parse(event.newValue)
                    commit("updateAuth", {
                        token: auth.token,
                        refreshToken: auth.refreshToken,
                        lastAuth: new Date(),
                    })
                } else if (event.key === 'logout') {
                    //console.log("not logout")
                    // Not use commit to avoid subscription triggering
                    state.auth = null
                    state.me = null
                    sessionStorage.removeItem("auth")
                }
            });

            // Get localStorage auth if rememberMe
            if (getRememberMe()) {
                //console.log("get localStorage auth")
                //console.log("aut:", JSON.parse(localStorage.getItem("auth")))
                // state.auth = JSON.parse(localStorage.getItem("auth"))
                commit("setAuth", JSON.parse(localStorage.getItem("auth")))

                commit("setInitialized", true)
                return
            }

            // Get sessionStorage auth, if not null the page refreshed, no need to ask to other tabs
            let authSessionStorage = JSON.parse(sessionStorage.getItem("auth"))
            if (authSessionStorage != null && authSessionStorage != "null") {
                //console.log("get sessionStorage auth")
                //console.log("aut:", authSessionStorage)
                // state.auth = authSessionStorage
                commit("setAuth", authSessionStorage)

                commit("setInitialized", true)
                return
            }

            // Ask to others tabs for auth
            // console.log("ask other tabs for storage")
            localStorage.setItem("getAuthStore", Date.now())
            localStorage.removeItem("getAuthStore")

            commit("setInitialized", true)
        }
    }
}
export default authStore

function getRememberMe() {
    //console.log("getRememberMe")
    //Get remember from localStorage
    let rememberMe = localStorage.getItem("rememberMe")
    if (rememberMe != null && rememberMe != "null") {
        //console.log("rememberMe is not null")
        //console.log("rememberMe:", rememberMe)
        //console.log("type rememberMe:", typeof rememberMe)
        // check rememberMe with ternary operator to avoid other type than boolean in rememberMe
        return rememberMe === "true" || rememberMe === true
    } else {
        //console.log("rememberMe is null")
        return false
    }
}

function updateStorage(jsonAuth) {
    if (jsonAuth == undefined) {
        return
    }
    if (getRememberMe()) {
        //console.log("rememberMe is true")
        localStorage.setItem("auth", jsonAuth)
    } else {
        //console.log("rememberMe is false")
        sessionStorage.setItem("auth", jsonAuth)
    }
}

export function authSubscription(state, mutation) {
    if (!state.initialized) {
        return
    }

    switch (mutation.type) {
        case "setAuth":
            //console.log("mutation setAuth")
            localStorage.setItem("updateAuth", JSON.stringify(state.auth));
            localStorage.removeItem("updateAuth")
            break;
        case "logout":
            //console.log("mutation logout")
            localStorage.setItem("logout", Date.now())
            localStorage.removeItem("logout")
            break;
    }
}

export function initAuthStore(state, mutation) {

}


