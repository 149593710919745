<template>
    <section class="w-100 pb-60 pt-0">
        <HeroVideo :url="'/assets/videos/movieclip.webm'"></HeroVideo>
        <section class="d-flex justify-content-center flex-column pt-60 pb-60">
            <div class="container px-0 mb-30">
                <h2 class="mb-0 text-capitalize text-left title-home" style="font-size: 40px;">Nos Meilleures ventes</h2>
                <h4 class="font-weight-bold" style="font-size: 20px;">Ces produits vont vous plaire</h4>
            </div>
            <div class="w-100">
                <CarouselItems v-if="!this.bestSelling.length" key="best-selling-loader" :carousel-for-load="true"></CarouselItems>
                <CarouselItems v-else :items="this.bestSelling" key="best-selling"></CarouselItems>
            </div>
        </section>
        <Parallax :image="'/assets/images/association-groupe-enfants.jpg'">
            <div class="container parallax-content pb-30 pt-30">
                <h2 class="mb-30" style="font-size: 50px"><b>1€ pour un Combat</b></h2>
                <p>Choix de cœur en venant sur notre site!
                    Vous participez de ce fait à une dynamique qui va au-
                    delà du paraître.</p>
                <p> Pour chaque article acheté, 1 € est reversé à la
                    <strong>FRM(Fondation pour Recherche Médicale)</strong> en faveur
                    de la recherche pour les maladies auto-immunes telles
                    que la sclérose en plaque, la maladie de basedow,
                    etc…</p>
            </div>
        </Parallax>
        <section class="d-flex justify-content-center flex-column pt-60 pb-60">
            <div class="container px-0 mb-30">
                <h2 class="mb-0 text-capitalize text-left title-home" style="font-size: 40px;">Les Nouveautés</h2>
                <h4 class="font-weight-bold" style="font-size: 20px;">Ces produits vont vous plaire</h4>
            </div>
<!--            <h2 class="text-center text-color-secondary">Les <strong>Nouveautés</strong></h2>-->
            <div class="w-100">
                <CarouselItems v-if="this.newItems.length" key="new-items" :items="this.newItems"></CarouselItems>
                <CarouselItems v-else key="new-items-loader" :carousel-for-load="true"></CarouselItems>
            </div>
        </section>
        <Collection v-for="(collection, index) in collections" :key="collection.id" :parallax-id="index" :collection="collection">
        </Collection>
    </section>
</template>

<script>
import Collection from "@/components/collections/Collection";
import Parallax from "@/components/widgets/Parallax";
import CarouselItems from "@/components/items/CarouselItems";
import HeroVideo from "@/components/widgets/HeroVideo";

export default {
    name: "Home",
    components: {
        Collection,
        Parallax,
        CarouselItems,
        HeroVideo
    },
    data() {
        return {
            newItems: [],
            bestSelling: [],
            collections: []
        }
    },
    created() {
        window.scrollTo(0,0)
        this.getCollections()
        this.getNewItems()
        this.getBestSelling()
    },
    methods: {
        getNewItems()
        {
            this.$itemService.getNews()
            .then(res => {
                this.newItems = res.data
            })
            .catch(error => {
                this.$store.dispatch("setError", error)
            })
        },
        getBestSelling()
        {
            this.$itemService.getNews()
            .then(res => {
                this.bestSelling = res.data
            })
            .catch(error => {
                this.$store.dispatch("setError", error)
            })
        },
        getCollections()
        {
            this.$collectionService.getAll()
            .then(res => {
                this.collections = res.data
            })
            .catch(error => {
                this.$store.dispatch("setError", error)
            })
        }
    }
}
</script>

<style scoped>

</style>