<template>
    <div class="d-flex justify-content-center align-items-center overflow-hidden" :style="style">
        <div class="embed-responsive embed-responsive-16by9">
            <div class="embed-responsive-item">
                <video class="img-responsive" id="video-hero-home" autoplay muted loop="true" height="auto" alt="quick cart">
                    <source type="video/webm" :src="source">
                </video>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "HeroVideo",
    data() {
        return {
            headerHeight: 0,
            currentTime: 0,
            video: null
        }
    },
    props: {
        url: String
    },
    mounted() {
        this.computeHeight()
        window.addEventListener("resize", this.computeHeight)
        // let video = document.getElementById("video-hero-home")
        // video.addEventListener("timeupdate", this.updateTime)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.computeHeight)
        // video.removeEventListener("timeupdate", this.updateTime)
    },
    computed: {
        style()
        {
            return {
                maxHeight: 'calc(80vh - ' + this.headerHeight + 'px)',
            }
        },
        source()
        {
            return this.url;
        }
    },
    methods: {
        computeHeight()
        {
            this.headerHeight = document.getElementById("header").clientHeight
        },
    }
}
</script>

<style scoped>

</style>