import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//import NavigationAnimator
import NavigationAnimator from "@/animators/NavigationAnimator"

Vue.prototype.$navigationAnimator = new NavigationAnimator()

// import services
import "@/services"

//import directives
import "@/directives"

//import all utils
import '@/utils'

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  beforeCreate() {
      this.$store.dispatch('initiateAuthStore')
  }
}).$mount('#app')
