<template>
    <!--    no-repeat center center fixed,-->
    <section style="overflow: hidden" :ref="'parallax-' + parallaxId" class="parallax-banner-container">
        <div id="image1" :class="'parallax-banner parallax parallax-' + parallaxId"
             :style="style">
            <span class="parallax-overlay"></span>
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    name: "Parallax",
    data() {
        return {
            viewportHeight: 0,
            isOnScreen: false,
            imageToTop: 0
        }
    },
    props: {
        parallaxId: Number,
        image: String,
    },
    methods: {
        getPosition()
        {
            let el = this.$refs['parallax-' + this.parallaxId];
            let top = el.getBoundingClientRect().top;

            if(top - this.viewportHeight < 100 && top - this.viewportHeight > 0) {
                this.isOnScreen = true
            } else if(top - this.viewportHeight < 0) {
                this.isOnScreen = true
                let startTranslate = 200;
                if(top - this.viewportHeight < - startTranslate) {
                    let maxTranslate = 10;
                    let toAdd = Math.abs(top - this.viewportHeight);
                    toAdd = maxTranslate/this.viewportHeight * toAdd;
                    this.imageToTop = 15 + toAdd;
                }
            }
        },
        computeHeight()
        {
            var height;

            // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
            if (typeof window.innerWidth != 'undefined')
            {
                height = window.innerHeight;
            }

            // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
            else if (typeof document.documentElement != 'undefined'
                && typeof document.documentElement.clientWidth !=
                'undefined' && document.documentElement.clientWidth != 0)
            {
                height = document.documentElement.clientHeight;
            }

            // older versions of IE
            else
            {
                height = document.getElementsByTagName('body')[0].clientHeight
            }
            this.viewportHeight = height
        }
    },
    computed: {
        style()
        {
            if(this.isOnScreen) {
                return {
                    backgroundImage: 'url(\'' + this.image + '\')',
                    backgroundAttachment: 'local',
                    backgroundSize: 'cover',
                    backgroundPosition: '50% ' + this.imageToTop + '%'
                }
            } else {
                return {
                    backgroundColor: 'grey'
                }
            }
        }
    },
    mounted() {
        this.computeHeight()
        this.getPosition()
        window.addEventListener("scroll", this.getPosition)
        window.addEventListener("resize", this.computeHeight)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.getPosition)
        window.removeEventListener("resize", this.computeHeight)
    }
}
</script>

<style scoped>
</style>
