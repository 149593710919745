export default class Animator {
    queue = []
    blocked = false

    BLOCKED = "BLOCKED"
    UNBLOCK_AT_END = "UNBLOCK_AT_END"
    NONE = "NONE"

    constructor() {}

    addToQueue(fun, time) {      
        //console.loglog("Add to queue, func : " + fun + " time : " + time)  
        this.queue.push({handler: fun, time: time})
    }

    deleteQueue() {
        this.queue = []
    }

    unblock() {
        this.addToQueue(() => {
            this.blocked = false
        }, 0)
        return this
    }

    isBlocked() {
        return this.blocked
    }

    execute() {
        let fun = this.queue.shift();
        if (fun === undefined) {
            //console.loglog("[Animator] func undefined, return")
            return  
        }
            
        //console.loglog("Execute func : " + fun.handler + ", next Action in : " + fun.time)    
        fun.handler();
        setTimeout(this.execute.bind(this), fun.time);
    }

    startFunction(now, interneCall) {
        if (typeof now != "boolean") {
            now = false
        }

        if (typeof interneCall != "boolean") {
            interneCall = false
        }

        let leave = this.blocked && !interneCall

        if (!interneCall && !this.blocked && !leave) {
            this.blocked = true
        }
        
        return {
            now: now,
            interneCall: interneCall,
            leave: leave
        }
    }

    endFunction(interneCall) {
        if (typeof interneCall != "boolean") {
            interneCall = false
        }        

        if (!interneCall) {
            //console.loglog("[Animator] endFunction, not internal")
            this.unblock()
            this.execute()
        } else {
            //console.loglog("[Animator] endFunction, internal")
        }
    }
}