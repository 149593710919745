import Service from './Service'

export default class SubcategoryService extends Service {
    constructor() {
        super(SubcategoryService.API);
    }

    getSizes(subcategoryId) {
        return this.requestGet(SubcategoryService.API + '/' + subcategoryId +'/sizes')
    }
}

SubcategoryService.API = "/subcategories/active"