<template>
    <div class="toaster" :class="classColor">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: "Toaster",
    props:  {
        id: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "success"
        }
    },
    computed: {
        classColor() {
            switch(this.type) {
                case "success":
                    return "bg-success text-white"
                case "error":
                    return "bg-danger text-white"
                case "warning":
                    return "bg-warning text-dark"
                case "info":
                    return "bg-info text-white"
                default:
                    return "bg-success text-white"
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.$store.dispatch("removeToaster", this.id)
        }, 5000)
    }
}
</script>