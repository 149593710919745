const cartStore = {
    namespaces: true,
    state: () => ({
        cart: {
            lines: []
        },
    }),
    getters: {
        getCart(state) {
            return state.cart
        }
    },
    mutations: {
        addLine(state, {itemColorId, sizeId, quantity}) {
            let idx = state.cart.lines.findIndex(el => el.itemColorId === itemColorId && el.sizeId === sizeId)
            if (idx === -1) {
                state.cart.lines = [{itemColorId: itemColorId, sizeId: sizeId, quantity: quantity, id: Math.random()}].concat(state.cart.lines)
            } else {
                state.cart.lines[idx].quantity += quantity
            }
        },
        removeLine(state, {itemColorId, sizeId, quantity}) {
            let idx = state.cart.lines.findIndex(el => el.itemColorId === itemColorId && el.sizeId === sizeId)
            if (idx !== -1) {
                state.cart.lines[idx].quantity -= quantity
                if (state.cart.lines[idx].quantity <= 0) {
                    state.cart.lines.splice(idx, 1)
                }
            }
        },
        setCart(state, cart) {
            state.cart = cart
        },
    },
    actions: {
        setCart({commit}, cart) {
            commit('setCart', cart)
        },
        addLineToCart({state, commit}, {itemColorId, sizeId, quantity}) {
            commit('addLine', {itemColorId, sizeId, quantity})
            localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        removeLineFromCart({state, commit}, {itemColorId, sizeId, quantity}) {
            commit('removeLine', {itemColorId, sizeId, quantity})
            localStorage.setItem('cart', JSON.stringify(state.cart))
        },
        initCart({commit}) {
            let cart = JSON.parse(localStorage.getItem('cart'))
            if (cart) {
                commit('setCart', cart)
            }
        },
        removeCart({commit, state}) {
            commit('setCart', {lines: []})
            localStorage.setItem('cart', JSON.stringify(state.cart))
        },
    }
}

export default cartStore

