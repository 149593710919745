<template>
    <div class="side-panel" ref="sidePanel">
        <div v-if="isOpened" class="page-overlay" :class="{ 'show' : isOpened }"></div>
        <div class="side-panel-container" :class="{ 'show' : isOpened }" v-click-outside="close">
            <div class="container d-flex h-100 align-items-center">
                <form action="#" method="post" class="sky-form w-100">
                    <div class="px-15">
                        <h2 class="text-center pb-30">Se connecter</h2>
                        <section class="registration" :class="{ 'shake' : shakeMessage }">
                            <div v-if="message.length" class="message-registration" :class="{ 'show' : message !== 'ok' }">
                                {{ message }}
                            </div>
                        </section>
                        <section class="registration" :class="{ 'shake' : shakeMessage }">
                            <div v-if="messageInfo.length" class="messageInfo-registration">
                                {{ messageInfo }}
                            </div>
                        </section>
                        <section class="registration">
                            <label class="input">
                                <i class="icon-append fa fa-envelope"></i>
                                <input class="registration" required="" type="email" placeholder="Email address" v-model="email">
                            </label>
                        </section>
                        <section class="registration">
                            <label class="input">
                                <i class="icon-append fa fa-lock"></i>
                                <input class="registration" required="" type="password" placeholder="Password" v-model="password">
                            </label>
                            <div class="note"><a href="#">Forgot Password?</a></div>
                        </section>
                        <section>
                            <label class="checkbox"><input type="checkbox" name="checkbox-inline" v-model="rememberMe"><i></i>Keep me logged in</label>
                        </section>
                    </div>
                    <footer class="d-flex flex-column">
                        <div class="note ml-0"><a href="#">Je n'ai pas de compte</a></div>
                        <button type="submit" class="btn btn-secondary ml-0 mr-0" @click.prevent="register">
                            <div v-if="loading" class="spinner-border text-light" role="status" style="width: 20px; height: 20px;">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <template v-else>Log in</template>
                        </button>
                    </footer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import preventScroll from "@/mixins/preventScroll";

export default {
    name: "SideRegistration",
    components: {
    },
    data(){
        return {
            email: "",
            password: "",
            rememberMe: false,
            message: "ok",
            loading: false,
            shakeMessage: false,
            isOpened: false,
        }
    },
    mixins: [
        preventScroll
    ],
    computed:
    {
        requireLogin()
        {
            return this.$store.state.requireLogin
        },
        auth() {
            return this.$store.getters.auth
        },
        messageInfo() {
            return this.$store.getters.getMessageInfo
        }
    },
    watch: {
        requireLogin: function(newValue) {
            if(newValue) {
                this.$navigationAnimator.openUserSidePanel()
            } else {
                this.$navigationAnimator.closeUserSidePanel()
            }
        }
    },
    methods: {
        onSidePanelClose()
        {
            this.$store.dispatch("setRequireLogin", false)
            this.$store.dispatch("setTo", null)
        },
        register() {
            this.loading = true
            this.shakeMessage = false
            this.message = "ok"

            this.$authService.authenticate(this.email, this.password)
            .then(res => {
                let auth = res.data
                this.$store.dispatch("setAuth", {auth: auth, rememberMe: this.rememberMe})

                this.message = "ok"
                this.$navigationAnimator.closeUserSidePanel()

                let lines = this.$store.state.cart.cart.lines
                let promeses = []
                lines.forEach(line => {
                    promeses.push(this.$cartService.addToCart(line.itemColorId, line.sizeId, line.quantity))
                })

                Promise.all(promeses) 
                .then(() => {})
                .catch(error => {
                    if (error.response?.status == 422) {
                        this.$store.dispatch('addToaster', {
                            text: "Certains de vos articles n'ont pas pu être ajoutés au panier. Stock épuisé",
                            type: "error",
                            key: "outOfStock",
                        })
                        return
                    }
                })
                .finally(() => {
                    this.$cartService.getCart()
                    .then((resp) => {
                        this.$store.dispatch('setCart', resp.data)
                    })
                    this.loading = false
                })
            })
            .catch(error => {
                if (error.response?.status == 422) {
                    this.errors = this.addErrors(error.response.data, this.errors)
                    return
                }
                if (error.response?.status == 401) {
                    this.message = "Email ou mot de passe invalide"
                    this.shakeMessage = true
                    return
                }
                this.$store.dispatch("setError", error)
            })
            .finally(() => { 
                this.loading = false
            })
        },
        close() {
            this.$navigationAnimator.closeUserSidePanel()
        }        
    },
    created() {
        this.$navigationAnimator.onChange(() => {
            this.isOpened = this.$navigationAnimator.userSidePanel
        })
    },
}
</script>