import { render, staticRenderFns } from "./HeroVideo.vue?vue&type=template&id=0bbb7616&scoped=true&"
import script from "./HeroVideo.vue?vue&type=script&lang=js&"
export * from "./HeroVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbb7616",
  null
  
)

export default component.exports