<template>
    <div class="item-for-list" ref="onScreen">
        <div class="embed-responsive embed-responsive-3by5">
            <div :id="'carousel-item-' + id" class="embed-responsive-item" data-ride="carousel">
                <div class="item-for-list-image-container"
                    v-if="(isOnScreen || hasBeenLoaded) && !itemForLoad">
                    <div class="item-for-list-image h-100 loading-skeleton"
                        :class="{ 'disable' : sourceImg.length }"
                        :style="{ 
                            backgroundImage: imagesSource,
                            backgourdSize: 'contain',
                            backgourdRepeat: 'no-repeat',
                        }"></div>
                </div>
                <div v-else class="item-for-list-image-container loading-skeleton"></div>
                <div v-if="itemForLoad" class="color-choice-container">
                    <div class="color-circle" v-for="i in 3" :key="i"></div>
                </div>
                <div v-else class="color-choice-container">
                    <div class="color-circle" v-for="(itemColor, key) in item.itemColors"
                         :key="key"
                         :class="{'active' : key === currentColor}"
                         @mouseenter="setColor(key)" @click="setColor(key)" :style="{ backgroundColor: itemColor.color.value }">
                    </div>
                </div>
            </div>
        </div>
<!--        <hr class="mt-0">-->
<!--        <router-link :to="{ name: 'produits' }" class="">-->
<!--            <div :>-->

<!--            </div>-->
<!--        </router-link>-->
<!--            <span :class="['shop-stock-info', true ? 'stock-yes' : 'stock-no'] ">-->
<!--                <i :class="['fa', true ? 'fa-check' : 'fa-times']"></i> {{ (true ? 'en ' : 'out of') + 'stock' }}-->
<!--            </span>-->
<!--        <div class="tag-container">-->
<!--            <span class="label label-tag" v-for="(tag, index) in item.tags" :key="index" :style="{ backgroundColor: tag.color }"><i class="fa fa-tag"></i>{{ tag.content }}</span>-->
<!--        </div>-->
        <div class="product-info d-flex flex-column"><!-- title and price -->
            <router-link v-if="!itemForLoad" :to="{ name: 'item', params: { id: item.id.toString() } }"
                         class="item-for-list-name d-inline-block text-truncate">
                {{ item.name }}
            </router-link>
            <div v-else class="item-for-list-name-skeleton loading-skeleton"></div>
            <div v-if="!itemForLoad" class="item-for-list-price-container">
                <span v-if="salePrice !==  price" class="sale-price price">{{ salePrice }}€</span>
                <span :class="{ 'line-through' : salePrice !==  price }" class="price">
                    {{ price }}€
                </span>
            </div>
            <div v-else class="item-for-list-price-skeleton loading-skeleton"></div>
            <StarsRating v-if="!itemForLoad" :average-mark="3.5" :nb-marks="3"></StarsRating>
            <div v-else class="item-for-list-stars-skeleton loading-skeleton"></div>
        </div>
    </div>
</template>
<script>
import StarsRating from "@/components/widgets/StarsRating";
import onScreen from "@/mixins/onScreen";
import number from "@/mixins/number";

export default {
    name: "ItemForList",
    mixins: [
        onScreen,
        number
    ],
    data() {
        return {
            id: null,
            currentColor: 0,
            sourceImg: [],
            activeColor: 0
        }
    },
    components: {
        StarsRating
    },
    props: {
        stock: {
            Boolean,
            default: true
        },
        md: {
            Number,
            default: 4
        },
        item: {
            Object,
            default: () => {return {}}
        },
        itemForLoad: {
            Boolean,
            default: false
        }
    },
    computed: {
        salePrice()
        {
            return this.formatNumber(this.item.price * (1 - this.item.discount / 100))
        },
        price()
        {
            return this.formatNumber(this.item.price)
        },
        imagesSource()
        {
            if(this.sourceImg.length)
                return this.sourceImg.reduce((oldValue, currentValue) => {
                    return 'url(' + currentValue + ')' + (oldValue.length ? ',' : '') + oldValue
                }, "")
            else
                return ''
        }
    },
    created() {
        this.id = this.uniqid()
        // //console.log(this.item)
        if(!this.itemForLoad) {
            this.uploadImg()
        }
    },
    methods: {
        uniqid(prefix = "", random = false) {
            const sec = Date.now() * 1000 + Math.random() * 1000;
            const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
            return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
        },
        setColor(key)
        {
            this.currentColor = key
            this.uploadImg()
        },
        uploadImg() {
            if(!this.item.itemColors.length) {
                return
            }

            if(!this.item.itemColors[this.currentColor].images.length) {
                return
            }

            let source = this.item.itemColors[this.currentColor].images[0].medium

            this.sourceImg = this.sourceImg.filter(el =>  { return el != source })

            this.sourceImg.push(source)
        }
    }
}
</script>

<style scoped>
</style>
