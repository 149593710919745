<template>
    <div class="container px-0">
        <VueSlickCarousel v-bind="setting">
            <template #prevArrow="arrowOption">
                <div class="slick-arrow"><i class="fa fa-chevron-left"></i></div>
            </template>
            <template #nextArrow="arrowOption">
                <div class="slick-arrow"><i class="fa fa-chevron-right"></i></div>
            </template>
            <template v-if="!carouselForLoad && items.length > 0">
                <ItemForList v-for="item in items" :item="item" :key="item.id" class="pr-md-4"></ItemForList>
            </template>
            <template v-else>
                <ItemForList v-for="i in 10" :item-for-load="true" class="pr-md-4" :key="'item-for-list-loading-' + i"></ItemForList>
            </template>
            <template name:default></template>
        </VueSlickCarousel>
    </div>
</template>

<script>
import StarsRating from "@/components/widgets/StarsRating";
import VueSlickCarousel from "vue-slick-carousel";
import ItemForList from "@/components/items/ItemForList";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    name: "CarouselItems",
    data() {
      return {
          isChanging: true,
          settingCarousel: {
              "dots": false,
              "nav": true,
              "edgeFriction": 0.35,
              "infinite": true,
              "speed": 500,
              "slidesToShow": 4,
              "slidesToScroll": 3,
              "swipeToSlide": true,
              "responsive": [
                  {
                      "breakpoint": 1024,
                      "settings": {
                          "slidesToShow": 3,
                          "slidesToScroll": 2,
                      }
                  },
                  {
                      "breakpoint": 760,
                      "settings": {
                          "slidesToShow": 2,
                          "slidesToScroll": 1,
                      }
                  },
                  {
                      "breakpoint": 480,
                      "settings": {
                          "slidesToShow": 1,
                          "slidesToScroll": 1,
                          "nav": false
                      }
                  },
              ]
          }
      }
    },
    components: {
        StarsRating,
        VueSlickCarousel,
        ItemForList
    },
    props: {
        autoplay: {
            Boolean,
            default : false
        },
        items: {
            Array,
            default:() => []
        },
        mw: {
            Number,
            default : 100
        },
        initialSlide: {
            Number,
            default: 0
        },
        carouselForLoad: {
            Boolean,
            default: false
        }
    },
    computed: {
        setting() {
            return {
                ...this.settingCarousel,
                ...{
                   "initialSlide": this.initialSlide
                }
            }
        }
    }
}
</script>

<style scoped>
</style>
