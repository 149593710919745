const toasterStore = {
    namespaces: true,
    state: () => ({
        toasters: [],
        toasterId: 0
    }),
    getters: {
        getToasters(state) {
            return state.toasters
        }
    },
    mutations: {
        addToaster(state, { text, type, key }) {
            state.toasters.push({ text, type , id: (state.toasterId++).toString(), key: key })
        },
        removeToaster(state, id) {
            console.log("removeToaster", id)
            state.toasters = state.toasters.filter(el => el.id !== id)
        },
    },
    actions: {
        addToaster({commit, state}, { text, type, key }) {
            if (state.toasters.findIndex(el => el.key === key) === -1) {
                commit('addToaster', { text, type, key })
            }
        },
        removeToaster({ commit}, id) {
            commit('removeToaster', id)
        },
    }
}

export default toasterStore

