import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './authStore';
import {authSubscription} from './authStore';
import cartStore from './cartStore';
import toasterStore from './toasterStore';
import errorStore from './errorStore';

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth: authStore,
        error: errorStore,
        cart: cartStore,
        toaster: toasterStore
    },
    actions: {
        initialiseStore() {
            //console.log("TODO: store initilisation")
        }
    }
});

store.subscribe((mutation, state) => {
    authSubscription(state.auth, mutation)
})

export default store