//Important, setRequiredClass on mounted, otherwise will not work

export default {
    data() {
        return {
            requiredClass: null
        }
    },
    methods: {
        preventDefault(event) {
            if(this.existInParents(event.target, this.requiredClass)) {
                return;
            }

            event.preventDefault();
        },
        preventDefaultForScrollKeys(event)
        {
            if(this.existInParents(event.target, this.requiredClass)) {
                return
            }

            const keys = {37: 1, 38: 1, 39: 1, 40: 1};

            if (keys[event.keyCode]) {
                this.preventDefault(event);
                return false;
            }
        },
        existInParents(el, requiredClass, hasElementScrollable = false) {
            if(!el.parentElement)
                return false

            if(!hasElementScrollable)
                hasElementScrollable = el.scrollHeight > el.scrollTopMax && el.scrollTopMax !== 0;

            return el.className.split(' ').includes(requiredClass) ? hasElementScrollable : this.existInParents(el.parentElement, requiredClass, hasElementScrollable)
        },
        setRequiredClass(requiredClass)
        {
            this.requiredClass = requiredClass
        }
    },
    watch: {
        isOpened: function(newValue) {
            if(newValue) {
                // modern Chrome requires { passive: false } when adding event
                let supportsPassive = false;
                try {
                    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                        get: function () { supportsPassive = true; }
                    }));
                } catch(e) {}

                let wheelOpt = supportsPassive ? { passive: false } : false;
                let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
                window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
                window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
                window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
                window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
            } else {
                // modern Chrome requires { passive: false } when adding event
                let supportsPassive = false;
                try {
                    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                        get: function () { supportsPassive = true; }
                    }));
                } catch(e) {}

                let wheelOpt = supportsPassive ? { passive: false } : false;
                let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

                window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
                window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
                window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
                window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
            }
        }
    }

}