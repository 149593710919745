<template>
    <div v-if="averageMark != null"  class="d-flex">
        <div v-for="(star, i) in arrayStars" :key="i" class="star-container" :class="{'big' : size == 'big'}">
            <i class=""  :class="star"></i>
        </div>
        <div v-if="nbMarks">
            <span class="text-small">({{ nbMarks }})</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "StarsRating",
    props: {
        averageMark: Number,
        nbMarks: Number,
        size: String
    },
    computed: {
        arrayStars() {
            let array = []
            for(let i = 0; i < 5; i++) {
                if(this.averageMark < i + 1 && this.averageMark > i) {
                    array.push('fa fa-star-half-o')
                } else if(this.averageMark >= i+1) {
                    array.push('fa fa-star')
                } else {
                    array.push('fa fa-star-o')
                }
            }
            return array;
        }
    }
}
</script>

<style scoped>

</style>
