import Service from './Service'
import {BASE_API} from './api' 

export default class Collection extends Service {
    constructor() {
        super(Collection.API);
    }
    
    getCollection(id) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet('/collections-page/' + id);
    }
}

Collection.API = "/collections/active"
export const API_COLLECTIONS_BANNER = "/banner";

export function getUploadBanner(id) {
    return BASE_API + Collection.API + "/" + id + API_COLLECTIONS_BANNER
}
