<template>
    <div v-if="cartLine" class="row mx-0 mw-100 cartLine">
        <div v-if="itemColor" class="col-3 px-0">
            <div class="embed-responsive embed-responsive-3by4">
                <div class="embed-responsive-item background-cover" :style="{ background: 'url(' + itemColor.images[0].small + ')' }"></div>
            </div>
        </div>
        <div v-if="itemColor && size" class="col-9 h-100 pr-0">
            <span v-if="item" @click="closeCart" class="text-truncate mw-100 d-block">
                <router-link :to="{name:'item', params: { id: item.id } }" class="item-name font-weight-bold">
                    {{ item.name }}
                </router-link>
            </span>
            <p class="p-0 mb-0"><span class="font-weight-bold">Quantité:</span> {{ cartLine.quantity }} <span class="font-weight-bold">Taille:</span> {{ size.name }}</p>
            <!-- <p class="p-0 mb-0 text-capitalize"> {{ cartLine.stock.color.color.name }}</p>
            <p class="p-0 mb-0"> {{ price }}€</p> -->
            <a href="#" class="cart-line-modifier" @click.stop.prevent="removeFromCart()">supprimer</a>
        </div>
        <div class="col-12 d-flex">
            <hr class="mt-0">
        </div>
    </div>
</template>

<script>
import number from "@/mixins/number";

export default {
    name: "CartLine",
    mixins: [
        number
    ],
    data() {
        return {
            item: null,
            itemColor: null,
            size: null
        }
    },
    props: {
        cartLine: Object
    },
    computed: {
        price()
        {
            if(!this.item) {
                return 0
            }
            return this.item.price
        },
        isConnected()
        {
            return this.$store.getters.isConnected
        }
    },
    methods: {
        closeCart()
        {
            this.$emit('closeCart')
        },
        removeFromCart()
        {
            if (!this.isConnected) {
                this.$store.dispatch("removeLineFromCart", { 
                    itemColorId: this.cartLine.itemColorId,
                    sizeId: this.cartLine.sizeId,
                    quantity: this.cartLine.quantity
                })
                return 
            }

            this.$cartService.removeFromCart(this.cartLine.id)
            .then(() => {
                this.$cartService.getCart()
                .then(resp => this.$store.dispatch('setCart', resp.data))
            })
            .catch(err => console.log(err))
        },
        getSize() {
            this.$sizeService.get(this.cartLine.sizeId)
            .then(resp => {
                this.size = resp.data
            })
            .catch(err => console.log(err))
        },
        getItemColor() {
            this.$itemColorService.get(this.cartLine.itemColorId)
            .then(resp => {
                this.itemColor = resp.data
                this.getItem()
                this.getSize()
            })
            .catch(err => console.log(err))
        },
        getItem() {
            this.$itemService.get(this.itemColor.itemId)
            .then(resp => {
                this.item = resp.data
            })
            .catch(err => console.log(err))
        }
    },
    created(){
        this.getItemColor()
    }
}
</script>

<style scoped>

</style>