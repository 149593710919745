<template>
    <header id="header" :class="['header', 'sticky-top', isOpened ? 'enter-up' : 'leave-up']">
        <header  id="topBar" :class="['topBar', 'styleBackgroundColor', 'd-flex', isOpened ? 'enter-up' : 'leave-up']" style="max-height:50px;">
            <div class="container-lg p-0 d-flex justify-content-between">
                <div class="fsize13 d-none d-md-flex flex-row align-items-center">
                    <a href="#" class="social fa fa-facebook"></a>
                    <a href="#" class="social fa fa-instagram"></a>
                </div>
                <div id="barMain" class="d-flex flex-grow-1">
                    <nav class="nav-second flex-grow-1">
                        <ul class="nav nav-pills nav-second d-flex justify-content-between justify-content-md-end">
                            <bottom-cart
                                ref="bottomCart">
                            </bottom-cart>
                            <bottom-user-panel
                                ref="bottomUserPanel">
                            </bottom-user-panel>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
        <header id="topNav">
            <div class="container-fluid container-lg p-0">
                <div class="d-flex justify-content-between nav-container-logo align-items-end px-3 p-lg-0 mb-10">
                    <div class="d-none d-lg-flex">
                        <img src="/assets/images/logo3.png" alt="" height="50">
                    </div>
                    <div class="d-lg-none">
                        <button class="btn btn-mobile" type="button" data-toggle="collapse" data-target="#navBar">
                            <i class="fa fa-bars"></i>
                        </button>
                        <router-link class="logo nav-mainlogo" to="/">
                            <img
                                src="/assets/images/logo2-mobile.png"
                                class="navbar-logo-company mobile"
                                alt="" />
                        </router-link>
                    </div>
                    <router-link class="logo nav-mainlogo d-none d-lg-block" to="/">
                        <img
                            src="/assets/images/logo2.png"
                            class="navbar-logo-company"
                            alt="" />
                    </router-link>
                    <div class="container-search">
                        <div class="search d-flex justify-content-end align-items-center" >
                            <input type="text" class="form-control mb-0" name="s" value="" placeholder="Search">
                            <i class="fa fa-search" style="position: absolute; margin-right:5px;">
                            </i>
                        </div>
                    </div>
                </div>
                <nav class="nav-main navbar navbar-expand-lg p-0">
                    <div class="collapse navbar-collapse" id="navBar">
                        <ul id="topMain" class="navbar-nav colo red">
                            <li class="nav-item">
                                <router-link class="router-class" exact to="/">Accueil</router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link class="router-class" :to="{ name: 'produits', params: { category: 'top-ventes' }}">
                                    Meilleures Ventes
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-class" :to="{name: 'produits', params: { category: 'soldes'}}">
                                    SOLDES
                                </router-link> -->
                            <!-- </li> -->
                            <template>
                                <li
                                    v-for="category in categories"
                                    class="nav-item dropdown nav-dropdown"
                                    :key="category.id">
                                    <router-link
                                        class="router-class dropdown-toggle"
                                        :id="'menu-category-' + category.id"
                                        role="button"
                                        data-hover="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :to="{
                                        name: 'items',
                                        params: { category : category.name }
                                    }">{{ category.name }} </router-link>
                                    <ul
                                        :aria-labelledby="'menu-category-' + category.id"
                                        class="dropdown-menu router-class p-0 border-0">
                                        <li
                                            v-for="subcategory in category.subcategories"
                                            class="dropdown-item p-0"
                                            :key="subcategory.id" >
                                            <router-link
                                                class="router-class"
                                                :to="{
                                                    name: 'items',
                                                    params: {
                                                        category: category.name,
                                                        subcategory: subcategory.name
                                                    }
                                                }"
                                            >{{ subcategory.name }}</router-link>
                                        </li>
                                        <li class="divider"></li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    </header>
</template>
<script>

import BottomCart from "@/components/cart/BottomCart";
import BottomUserPanel from "@/components/auth/BottomUserPanel";

export default {
    name: "Navigation",
    components: {
        BottomCart,
        BottomUserPanel
    },
    data() {
        return {
            isOpened: true,
            categories: []
        }
    },
    beforeDestroy() {
        this.$navigationAnimator.closeListener()
    },
    methods: {
        getNavigation() {
            this.$navigationAnimator.onChange(() => {
                this.isOpened = this.$navigationAnimator.topBarOpened()
            })
            this.$categoryService.getAll()
            .then(res => {
                this.categories = res.data
            })
            .catch(error => {
                this.$store.dispatch("setError", error)
            })
        }
    },
    created() {
        this.getNavigation()
    }
}
</script>