<template>
  <div id="wrapper" class="d-flex flex-column flex-grow-1" :style="{ top: '-' + toTop + 'px' }" :class="{ 'fixed' : pageFixed }">
      <side-cart ref="sideCart"></side-cart>
      <side-registration ref="sideRegistration"></side-registration>
      <transition name="fade">
          <div v-if="true" key="1" class="flex-grow-1 d-flex flex-column">
              <!-- <navigation @cartOpened="onCartOpened()" @cartClosed="onCartClosed()"></navigation> -->
              <navigation></navigation>
              <div class="flex-grow-1 d-flex mw-100">
                  <router-view :key="$route.path"></router-view>
              </div>
              <div class="toaster-container">
                  <toaster v-for="toaster in toasters" :key="toaster.id" :text="toaster.text" :id="toaster.id" :type="toaster.type"></toaster>
              </div>
              <Footer></Footer>
          </div>
          <div v-else class="page-loader d-flex">
              <Loader></Loader>view
          </div>
      </transition>
  </div>
</template>

<script>
import Navigation from "@/components/navigation/Navigation";
import Footer from "@/components/navigation/Footer";
import SideRegistration from "@/components/auth/SideRegistration";
import SideCart from "@/components/cart/SideCart";
import Toaster from "@/components/widgets/Toaster";

export default {
    name: "App",
    components: {
        Navigation,
        Footer,
        SideRegistration,
        SideCart,
        Toaster
    },
    data() {
        return {
            fixed: false,
            toTop: 0,
        }
    },
    computed: {
        onLoad()
        {
            return this.$store.state.navOnLoad
        },
        pageFixed() {
            return this.fixed
        },
        isConnected()
        {
            return this.$store.getters.isConnected
        },
        toasters ()
        {
            return this.$store.getters.getToasters
        }
    },
    created() {
        if(this.isConnected) {
            this.$cartService.getCart()
            .then(resp => this.$store.dispatch('setCart', resp.data))
        } else {
            this.$store.dispatch("initCart")
        }
    },
}
</script>

<style lang="less" src="@/assets/styles/app.less"></style>
