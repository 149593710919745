import Service from './Service'

export default class CartService extends Service {
    constructor() {
        super(CartService.API);
    }

    getCart() {
        return this.requestGet(CartService.API)
    }

    addToCart(itemColorId, sizeId, quantity) {
        return this.requestPost(CartService.API + '/add', {
            sizeId: sizeId,
            itemColorId: itemColorId,
            quantity: quantity,
        })
    }

    setQuantity(cartLineId, quantity) {
        return this.requestPut(CartService.API + '/quantiy', {
            cartLineId: cartLineId,
            quantity: quantity,
        })
    }

    removeFromCart(cartLineId) {
        return this.requestDelete(CartService.API + '/' + cartLineId)
    }
}

CartService.API = "/cart"