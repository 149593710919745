//Important, parent div must have "onScreen" ref in order to get the node

export default {
    data()
    {
        return {
            edge: 200,
            viewportHeight: 0,
            viewportWidth: 0,
            isMounted_OnScreen: false,
            isOnScreen: false,
            hasBeenLoaded: false,
            isTransitioning: false
        }
    },
    methods: {
        computeHeight()
        {
            var height;

            // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
            if (typeof window.innerHeight != 'undefined')
            {
                height = window.innerHeight;
            }

            // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
            else if (typeof document.documentElement != 'undefined'
                && typeof document.documentElement.clientHeight !=
                'undefined' && document.documentElement.clientHeight != 0)
            {
                height = document.documentElement.clientHeight;
            }

            // older versions of IE
            else
            {
                height = document.getElementsByTagName('body')[0].clientHeight
            }
            this.viewportHeight = height
        },
        computeWidth()
        {
            var width;

            // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
            if (typeof window.innerWidth != 'undefined')
            {
                width = window.innerWidth;
            }

            // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
            else if (typeof document.documentElement != 'undefined'
                && typeof document.documentElement.clientWidth !=
                'undefined' && document.documentElement.clientWidth != 0)
            {
                width = document.documentElement.clientWidth;
            }

            // older versions of IE
            else
            {
                width = document.getElementsByTagName('body')[0].width
            }
            this.viewportWidth = width
        },
        onScreen()
        {
            if(!this.isMounted_OnScreen)
                return;

            let isBelowViewport = true;
            let isOverViewport = true;
            let isAfterTheViewport = true; //On the right

            let el = this.$refs["onScreen"];
            if(el ===  undefined) {
                this.isOnScreen = true
                return
            }

            let top = el.getBoundingClientRect().top;
            let bottomTop = top + el.clientHeight
            let right = el.getBoundingClientRect().right;
            right -= el.clientWidth //sub the size of the element

            if(
                (top - this.viewportHeight < this.edge && top - this.viewportHeight > 0)
                || top - this.viewportHeight < 0
            )
                isBelowViewport = false

            if(
                bottomTop > 0
            )
                isOverViewport = false

            if(
                (right - this.viewportWidth < this.edge && right - this.viewportWidth > 0)
                || right - this.viewportWidth < 0
            )
                isAfterTheViewport = false

            this.isOnScreen =  !isAfterTheViewport && !isBelowViewport && !isOverViewport;

            if(this.isOnScreen)
                this.hasBeenLoaded = true

            if(this.isTransitioning)
                setTimeout(() => { this.onScreen() }, 150)
        },
        startOnScreen()
        {
            this.isTransitioning = true
            this.onScreen()
        },
        endOnScreen()
        {
            this.isTransitioning = false
        }
    },
    mounted()
    {
        this.isMounted_OnScreen = true
        this.computeHeight()
        this.computeWidth()
        window.addEventListener("scroll", this.onScreen)
        window.addEventListener("transitionstart", this.startOnScreen)
        window.addEventListener("transitionend", this.endOnScreen)
        window.addEventListener("resize", this.computeHeight)
        window.addEventListener("resize", this.computeWidth)
        this.onScreen()
    },
    beforeDestroy()
    {
        window.removeEventListener("scroll", this.onScreen)
        window.addEventListener("transitionstart", this.startOnScreen)
        window.removeEventListener("transitionend", this.endOnScreen)
        window.removeEventListener("resize", this.computeHeight)
        window.removeEventListener("resize", this.computeWidth)
    },
}