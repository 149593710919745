<template>
    <footer id="footer">
        <div class="container">
            <div class="row">
                <div class="logo_footer dark col-md-4">
                    <img alt="gexist" src="/assets/images/logo2.png" height="50" class="logo" />
                    <p class="block">
                        {{ info.street }}<br />
                        {{ info.town }}<br />
                        FRANCE<br />
                        Email: {{ info.mail }}<br />
                        Phone: {{ info.phone }}<br />
<!--                        Fax: +40 (0) 111 1234 568<br />-->
                    </p>
                    <p class="block"><!-- social -->
                        <a href="#" class="social fa fa-facebook"></a>
                        <a href="#" class="social fa fa-instagram"></a>
                    </p>
                </div>
                <div class="spaced col-md-4 col-sm-4">
                    <h4>Our <strong>Services</strong></h4>
                    <ul class="list-unstyled">
                        <li>
                            <a class="block" href="#">Qui-sommes nous</a>
                        </li>
                        <li>
                            <a class="block" href="#">Mentions légales</a>
                        </li>
                        <li>
                            <a class="block" href="#">Politique de confidentialité</a>
                        </li>
                        <li>
                            <a class="block" href="#">CGV</a>
                        </li>
                    </ul>
                </div>
                <div class="spaced col-md-4 col-sm-4">
                    <h4><strong>GEXIST</strong><br>
                        Une marque, un style inimitable et
                        intemporel</h4>
                    <!-- <p>
                        GEXIST est née en 2016.
                        Nos valeurs comportent le culte de l’assouvissement
                        de nos envies et un réel besoin d’être en accord avec
                        notre temps et notre environnement.
                        A travers ses créations originales et design, GEXIST
                        exprime un état de liberté profond et s’en inspire
                        pour souligner et sublimer la silhouette masculine
                        avec des matières de qualité pour un look viril et
                        moderne.
                        Au-delà du paraître, GEXIST a fait le choix intrinsèque
                        de conforter sa politique en soutenant la recherche
                        médicale au profit des maladies auto-immunes.                    </p> -->

                    <h4><small>Subscribe to our Newsletter</small></h4>
                    <form id="newsletterSubscribe" method="post" action="php/newsletter.php" class="input-group row p-0 m-0">
                        <input required type="email" class="form-control col-8 m-0" name="newsletter_email" id="newsletter_email" value="" placeholder="E-mail Address">
                        <span class="input-group-btn col-4 m-0 p-0">
                            <button class="btn btn-primary">SUBMIT</button>
                        </span>
                    </form>
                </div>
            </div>
        </div>
        <hr />
        <div class="copyright">
            <div class="container text-center fsize12">
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            info: {
                street: '17 Rue des Albizzias',
                town: '79180 Chauray',
                phone: '0610141667',
                mail: 'contact@gexist-gladiator.com'
            }
        }
    }
}
</script>
