import Service from './Service'

export default class UserService extends Service {
    constructor() {
        super(UserService.API);
    }

    getMe() {
        return this.requestGet(this.api);
    }

    setMe(user) {
        return this.requestPut(this.api, user);
    }

    changeEmail(request) {
        return this.requestPut("change-email", request);
    }

    getEmailChange() {
        return this.requestGet("email-change");
    }

    emailChangeValidation(request) {
        return this.requestPost("email-change-validation", request);
    }

    changePassword(request) {
        return this.requestPut("change-password", request);
    }

}

UserService.API = "me"