import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/items/:category?/:subcategory?',
		name: 'items',
		props: true,
		component: function () {
			return import('../views/ItemsView.vue')
		}
	},
	{
		path: '/collection/:id',
		name: 'collection',
		props: true,
		component: function () {
			return import('../views/CollectionView.vue')
		}
	},
	{
		path: '/item/:id',
		name: 'item',
		props: true,
		component: function () {
			return import('../views/ItemView.vue')
		}
	},
	{
		path: '/user',
		name: 'user',
		component: function () {
			return import('../views/UserView.vue')
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
