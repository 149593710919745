<template>
    <li class="d-flex navbar-icon align-self-center position-relative">
        <a href="#" class="navbar-icon" @click.prevent.stop="click">
            <i class="li li-bag"></i> <span class="cart-button-info-nb-article">{{ nbItemsInCart }}</span>
        </a>        
        <div class="bottom-panel" :class="{ 'show' : isOpened }"  v-click-outside="close" v-on="$listeners">
            <div class="py-15">
                <template v-if="cart">
                    <h3 class="text-center mb-10 px-15">Mon panier</h3>
                    <transition-group tag="div" class="cart-lines-container p-15" name="list-fade-height">
                        <cart-line v-for="cartLine in cart.lines" :key="cartLine.id"
                                @closeCart="close"
                                :cart-line="cartLine"
                                class="list-fade-height-item"></cart-line>
                    </transition-group>
                    <div class="d-flex flex-column py-0 px-15">
                        <!-- <div class="d-flex justify-content-between p-15 cart-total-price">
                            <span class="font-weight-bold">Total:</span> <span>{{ totalCartPrice }} €</span>
                        </div> -->
                        <!-- <div class="p-0 flex-grow-1">
                            <button class="btn btn-secondary btn-sm btn-cart text-uppercase w-100" @click="openCartSidePanel">
                                mon panier
                            </button>
                        </div> -->
                        <div class="p-0 flex-grow-1">
                            <router-link
                                exact to="shop-page-checkout.html"
                                class="btn btn-primary btn-sm btn-cart text-uppercase w-100"
                            >
                                payer
                            </router-link>
                        </div>
                    </div>
                </template>
                <div v-else class="mt-3 mx-3">
                    <h5 class="text-color-secondary text-center font-weight-bold">Votre panier est vide</h5>
                    <p class="text-center text-secondary p-0">Decrouvrez nos collections</p>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import number from "@/mixins/number";
import CartLine from "./CartLine";

export default {
    name: "BottomCart",
    components: {
        CartLine,
    },
    data() {
        return {
            isOpened: false
        }
    },
    mixins: [
        number,
    ],
    methods: {
        open()
        {
            this.$navigationAnimator.openCartBottomPanel()
        },
        close()
        {
            this.$navigationAnimator.closeCartBottomPanel()
        },
        click()
        {
            if(!this.isOpened) {
                this.open()
            } else {
                this.close()
            }
        },
        openCartSidePanel() {
            this.$navigationAnimator.openCartSidePanel()
        }
    },
    computed: {
        cart() {
            return this.$store.getters.getCart
        },
        nbItemsInCart() {
            if(this.$store.getters.getCart === null) {
                return 0
            }
            try {
                return this.$store.getters.getCart.lines.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
            }catch(e) {
                return 0
            }
        }
        // totalCartPrice()
        // {
        //     return this.formatNumber(
        //         this.cart.lines.reduce((oldValue, newValue) => newValue.quantity * newValue.stock.color.item.salePrice + oldValue, 0)
        //     )
        // }
    },
    created() {
        this.$navigationAnimator.onChange(() => {
            this.isOpened = this.$navigationAnimator.cartBottomPanel
        })
    }
}
</script>

<style scoped>

</style>