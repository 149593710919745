<template>
    <div class="side-panel" ref="sidePanel">
        <div v-if="isOpened" class="page-overlay" :class="{ 'show' : isOpened }"></div>
        <div class="side-panel-container" :class="{ 'show' : isOpened }" v-click-outside="close">
            <div class="side-cart p-30">
                <h2 class="text-center pt-10 pb-30 font-weight-normal">Mon panier</h2>
                <transition-group tag="div" class="side-cart-lines" name="list-fade-height">
                    <side-cart-line class="list-fade-height-item" v-for="line in cart.lines" :key="line.id" :cart-line="line" @lockSidePanel="lockSidePanel"></side-cart-line>
                </transition-group>
                <!-- <div class="p-0">
                    <h3 class="py-10 font-weight-normal">Total: {{ price }}€</h3>
                    <router-link exact to="shop-page-checkout.html" class="btn btn-primary btn-sm btn-cart text-uppercase w-100">payer</router-link>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import SideCartLine from "./SideCartLine";
import number from "@/mixins/number";

export default {
    name: "SideCart",
    mixins: [
        number
    ],
    components: {
        SideCartLine
    },
    data() {
        return {
            isOpened: false
        }
    },
    methods: {
        close()
        {
            this.$navigationAnimator.closeCartSidePanel()
        },
        lockSidePanel()
        {
        }
    },
    computed: {
        cart()
        {
            return this.$store.getters.getCart
        },
        // price()
        // {
        //     return this.formatNumber(
        //         this.cart.reduce((oldValue, newValue) => {
        //             return oldValue + newValue.quantity * newValue.stock.color.item.salePrice;
        //         }, 0)
        //     )
        // }
    },
    created() {
        this.$navigationAnimator.onChange(() => {
            this.isOpened = this.$navigationAnimator.cartSidePanel
        })
    }
}
</script>

<style scoped>

</style>