<template>
    <div class="row mx-0 mb-15">
        <div class="col-3 px-0">
            <div class="embed-responsive embed-responsive-3by4">
                <div class="embed-responsive-item background-cover" :style="{ background: 'url(' + 'image' + ')' }"></div>
            </div>
        </div>
        <div class="col-9 p-10 side-cart-line">
            <router-link :to="{ name:'item', params: { id: 'test' } }" class="text-truncate p-0 mb-5">
                {{ "itemname" }}
            </router-link>
            <p class="p-0 mb-5 text-capitalize">{{ "itemName" }}</p>
            <p class="p-0 mb-5 font-weight-bold">{{ "sizeName" }}</p>
            <div class="p-0 mb-5 d-flex flex-row justify-content-between ">
                <span class="d-flex flex-row">
                    <span class="side-cart-quantity-modifier" :class="{'disabled' : cartLine.quantity === 1}">
                        <i class="lni lni-minus"></i>
                    </span>
                    <span class="side-cart-quantity mx-10">{{ cartLine.quantity }}</span>
                    <span class="side-cart-quantity-modifier" >
                        <i class="lni lni-plus"></i>
                    </span>
                </span>
                <span class="d-flex flex-row">
                    <p class="p-0 mb-5 mx-10 side-cart-price">{{ price }}€</p>
                    <span class="side-cart-quantity-modifier">
                        <i class="lni lni-trash-can"></i>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import number from "@/mixins/number";

export default {
    name: "SideCartLine",
    props: {
        cartLine: Object
    },
    mixins: [
        number
    ],
    computed: {
        price()
        {
            return 0
            // return this.formatNumber(this.cartLine.stock.color.item.salePrice * this.cartLine.quantity)
        }
    },
    methods: {
        // deleteFromCart(stockId)
        // {
        //     this.$emit("lockSidePanel")
        //     this.$store.dispatch("deleteFromCart", stockId)
        // },
        // addToCart(stockId)
        // {
        //     this.$store.dispatch("addToCart", { stockId: stockId })
        // },
        // subToCart(stockId)
        // {
        //     if(this.cartLine.quantity === 1)
        //         return
        //     this.$emit("lockSidePanel")
        //     this.$store.dispatch("subToCart", stockId)
        // },
    }
}
</script>

<style scoped>

</style>