import Animator from "./Animator";


const TOP_BAR_DURATION = 300;
const BOTTOM_PANEL_DURATION = 400;
const SIDE_PANEL_DURATION = 300;
const PAUSE_BETWEEN_ANIMATION = 0;

export default class NavigationAnimator extends Animator {

    id = Math.round(Math.random(5)*1000000)
    cartBottomPanel = false
    cartSidePanel = false
    userBottomPanel = false
    userSidePanel = false
    topBar = false
    scroll = {
        wheelOpt: false,
        current: 0,
        old: 0,
        direction: "down",
        distance: 0,
    }

    subscribres = []

    constructor() {
        super();
        this.initListener();
    }

    onChange(callback) {
        this.subscribres.push(callback)
    }

    triggerSubscriber() {
        this.subscribres.forEach(subscribre => subscribre())
    }

    setTopBar(value) {
        this.topBar = value
        this.triggerSubscriber()
    }

    setCartSidePanel(value) {
        this.cartSidePanel = value
        this.triggerSubscriber()
    }

    setCartBottomPanel(value) {
        this.cartBottomPanel = value
        this.triggerSubscriber()
    }

    setUserSidePanel(value) {
        this.userSidePanel = value
        this.triggerSubscriber()
    }

    setUserBottomPanel(value) {
        this.userBottomPanel = value
        this.triggerSubscriber()
    }

    setScrollDirection(value) {
        let oldValue = this.scroll.direction
        this.scroll.direction = value
        if(value !== oldValue) {
            this.triggerSubscriber()
        }
    }

    setScrollCurrent(value) {
        let oldValue = this.scroll.current
        this.scroll.current = value
        if ((value === 0 && oldValue !== 0) || (oldValue == 0 && value != 0)) {
            this.triggerSubscriber()
        }
    }

    topBarOpened() {
        return this.topBar || this.scroll.direction === "up" || this.scroll.current === 0;
    }

    openTopBar(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] openTopBar, input: ", input) 
       

        if (input.leave) {
            return
        }

        if (!this.topBarOpened()) {
            this.addToQueue(() => {
                this.setTopBar(true)
            }, now ? 0 : TOP_BAR_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    closeTopBarIfRequired() {        
        //console.loglog("[NavigationAnimator] closeTopBarIfRequired")

        if(this.topBar) {
            this.addToQueue(() => {
                this.setTopBar(this.cartBottomPanel || this.userBottomPanel)
            }, TOP_BAR_DURATION + PAUSE_BETWEEN_ANIMATION)
        }
    }

    closeTopBar(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] closeTopBar, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] closeTopBar, leave")
            return
        }

        if(this.topBarOpened()) {
            //Close all the element in the same time
            this.closeUserBottomPanel(true, true)
            this.closeCartBottomPanel(true, true)
            this.closeCartSidePanel(true, true)
            this.closeUserSidePanel(true, true)

            //Wait that they all closed
            this.addToQueue(() => {}, Math.max(BOTTOM_PANEL_DURATION, SIDE_PANEL_DURATION) + PAUSE_BETWEEN_ANIMATION)

            //Close the topbar
            this.addToQueue(() => {
                this.setTopBar(false)
            }, now ? 0 : TOP_BAR_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    getCartBottomPanel() {
        return this.cartBottomPanel
    }

    openCartBottomPanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        // //console.log("[NavigationAnimator] openCartBottomPanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] openCartBottomPanel, leave")
            return
        }

        if(!this.cartBottomPanel) {
            this.openTopBar(false, true)
            this.closeUserBottomPanel(true, true)
            this.addToQueue(() => {
                this.setCartBottomPanel(true)
            }, now ? 0 : BOTTOM_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    closeCartBottomPanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        // //console.log("[NavigationAnimator]  closeCartBottomPanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] closeCartBottomPanel, leave")
            return
        }

        if(this.cartBottomPanel){
            this.addToQueue(() => {
                this.setCartBottomPanel(false)
            }, now ? 0 : BOTTOM_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)

            this.closeTopBarIfRequired()
        }

        this.endFunction(input.interneCall)
    }

    clickCartBottomPanel() {
        if (this.cartBottomPanel) {
            this.closeCartBottomPanel(false, true)
        } else {
            this.openCartBottomPanel(false, true)
        }
    }

    openCartSidePanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] openCartSidePanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] openCartSidePanel, leave")
            return
        }

        if(!this.userSidePanel) {
            this.openTopBar(false, true)
            this.closeUserBottomPanel(true, true)
            this.closeCartBottomPanel(true, true)
            this.addToQueue(() => {
                this.setCartSidePanel(true)
            }, now ? 0 : BOTTOM_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    closeCartSidePanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] closeCartSidePanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] closeCartSidePanel, leave")
            return
        }

        if(this.cartSidePanel){
            this.addToQueue(() => {
                this.setCartSidePanel(false)
            }, now ? 0 : SIDE_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    openUserBottomPanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        // //console.log("[NavigationAnimator] openUserBottomPanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] openUserBottomPanel, leave")
            return
        }

        if(!this.userBottomPanel){
            this.closeCartBottomPanel(true, true)
            this.closeCartSidePanel(true, true)
            this.closeUserSidePanel(true, true)
            this.addToQueue(() => {
                this.setUserBottomPanel(true)
            }, now ? 0 : SIDE_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    closeUserBottomPanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] closeUserBottomPanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] closeUserBottomPanel, leave")
            return
        }

        if(this.userBottomPanel) {
            this.addToQueue(() => {
                this.setUserBottomPanel(false)
            }, now ? 0 : SIDE_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)

            this.closeTopBarIfRequired()
        }

        this.endFunction(input.interneCall)
    }

    clickUserBottomPanel() {
        if (this.userBottomPanel) {
            this.openUserBottomPanel(false, true)

        } else {
            this.closeUserBottomPanel(false, true)
        }
    }

    openUserSidePanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] openUserSidePanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] openUserSidePanel, leave")
            return
        }

        if(!this.userSidePanel){
            this.closeUserBottomPanel(true, false)
            this.closeCartBottomPanel(true, false)
            this.closeCartSidePanel(true, false)
            this.addToQueue(() => {
                this.setUserSidePanel(true)
            }, now ? 0 : SIDE_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    closeUserSidePanel(now, interneCall) {
        let input = this.startFunction(now, interneCall)
        //console.loglog("[NavigationAnimator] closeUserSidePanel, input: ", input)

        if(input.leave) {
            //console.loglog("[NavigationAnimator] closeUserSidePanel, leave")
            return
        }
        
        if(this.userSidePanel) {
            this.addToQueue(() => {
                this.setUserSidePanel(false)
            }, now ? 0 : SIDE_PANEL_DURATION + PAUSE_BETWEEN_ANIMATION)
        }

        this.endFunction(input.interneCall)
    }

    onScroll() {
        if(window.scrollY === 0) {
            this.resetScrollValues()
        }

        if(this.cartBottomPanel || this.userBottomPanel) {
            return
        }

        this.setScrollCurrent(window.scrollY)

        this.scroll.distance += this.scroll.current - this.scroll.old;

        if(this.scroll.distance > 75) {
            this.setScrollDirection("down")
            this.scroll.distance = 0
        } else if(this.scroll.distance < -75) {
            this.setScrollDirection("up")
            this.scroll.distance = 0
        }

        this.scroll.old = this.scroll.current
    }


    resetScrollValues() {
        let oldValue = {
            current: this.scroll.current,
            old: this.scroll.old,
            distance: this.scroll.distance,
            direction: this.scroll.direction,
        }
        let resetValue = {
            current: 0,
            old: 0,
            distance: 0,
            direction: "down",
        }
        this.scroll = {
            ...this.scroll,
            ...resetValue
        }
        if(JSON.stringify(resetValue) != JSON.stringify(oldValue)) {
            this.triggerSubscriber()
        }
    }

    initListener() {
        let supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; }
            }));
        } catch(e) {}

        this.scroll.wheelOpt = supportsPassive ? { passive: false } : false;

        addEventListener("scroll", this.onScroll.bind(this))
        addEventListener("resize",this.onScroll.bind(this))

        addEventListener('DOMMouseScroll', this.onScroll.bind(this), false); // older FF
        addEventListener('touchmove', this.onScroll.bind(this), this.scroll.wheelOpt); // mobile
    }


    closeListener() {
        removeEventListener("scroll",this.onScroll)
        removeEventListener("resize",this.onScroll)

        removeEventListener('DOMMouseScroll', this.onScroll, false); // older FF
        removeEventListener('touchmove', this.onScroll, this.scroll.wheelOpt); // mobile
    }
}