<template>    
    <li class="d-flex navbar-icon align-self-center position-relative">
        <a href="#" class="navbar-icon" @click.prevent.stop="onClickButtonUser">
            <i  v-if="isConnected" class="li li-user"></i>
            <i  v-else class="lni lni-power-switch"></i>
            <!-- <span class="user-connected"></span> -->
        </a>
        <div class="bottom-panel" :class="{ 'show' : isOpened }"  v-click-outside="close">
            <div class="p-15">
                <div class="bup-user-header">
                    <div class="bup-user-icon-container">
                        <i class="fa fa-user bup-user-icon"></i>
                    </div>
                    <p class="bup-user-name text-capitalize">{{ userFullName }}</p>
                </div>
                <hr class="my-15">
                <div class="d-flex flex-column py-0">
                    <ul class="px-0">
                        <li v-if="isConnected" class="bup-link">
                            <router-link :to="{ name: 'user'}">Mon compte</router-link>
                            <!-- <a href="/admin">Mon compte</a> -->
                        </li>
                        <li v-if="isAdmin" class="bup-link"><a :href="adminUrl">Administration</a></li>
                    </ul>
                </div>
                <div class="d-flex flex-column py-0">
                    <div class="p-0 flex-grow-1">
                        <button class="btn btn-danger text-white btn-sm btn-cart text-uppercase w-100" @click="logout">
                            déconnexion
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>

export default {
    name: "BottomUserPanel",
    components: {
    },
    data() {
        return {
            isOpened: false,
            adminUrl: process.env.VUE_APP_ADMIN_HOST
        }
    },
    methods: {
        close()
        {
            this.$navigationAnimator.closeUserBottomPanel()
        },
        logout()
        {
            this.$store.dispatch("logout")
            this.$navigationAnimator.closeUserBottomPanel()
            this.$store.dispatch("removeCart")
        },
        onClickButtonUser()
        {
            if(this.isOpened) {
                this.$navigationAnimator.closeUserBottomPanel()
                return
            }

            if(this.isConnected) {
                this.$navigationAnimator.openUserBottomPanel()
            } else  {
                this.$navigationAnimator.openUserSidePanel()
            }
        },
    },
    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin
        },
        userFullName()
        {
            return this.$store.getters.fullname
        },
        isConnected()
        {
            return this.$store.getters.isConnected
        }
    },
    created() {
        this.$navigationAnimator.onChange(() => {
            this.isOpened = this.$navigationAnimator.userBottomPanel
        })
    }
}
</script>

<style scoped>

</style>