import Service from './Service'

export default class Filter extends Service {
    constructor() {
        super(Filter.API);
    }

    getBy(queries) {
        return this.requestGet(this.api, queries);
    }
}

Filter.API = "/filters/active"
